<template>
  <b-sidebar
    id="sidebar-edit-contact"
    sidebar-class="sidebar-lg"
    bg-variant="white"
    shadow
    backdrop
    no-header
    right
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="
          d-flex
          justify-content-between
          align-items-center
          content-sidebar-header
          px-2
          py-1
        "
      >
        <h5 class="mb-0">Edit Contact</h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- Body -->
      <validation-observer
        ref="contactsEditFormValidator"
        #default="{ invalid, pristine }"
      >
        <b-form
          class="p-2"
          @submit.prevent="editContacts"
          ref="contactsEditForm"
        >
          <b-form-group label="Contact Group" label-for="group">
            <validation-provider
              #default="{ errors }"
              name="Group"
              vid="group"
              rules="required"
            >
              <v-select
                v-model="group"
                :options="groups"
                label="text"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :reduce="(group) => group.value"
                multiple
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label="Contact Country" label-for="country">
            <validation-provider
              #default="{ errors }"
              name="Country"
              vid="country"
              rules="required"
            >
              <v-select
                v-model="country"
                :options="countries"
                label="text"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :reduce="(country) => country.value"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- first name -->
          <b-form-group label-for="firstName" label="First Name">
            <validation-provider
              #default="{ errors }"
              name="firstName"
              vid="firstName"
              rules="required"
            >
              <b-form-input
                id="firstName"
                v-model="firstName"
                name="firstName"
                placeholder="John"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- last name -->
          <b-form-group label-for="lastName" label="Last Name">
            <validation-provider
              #default="{ errors }"
              name="lastName"
              vid="lastName"
              rules="required"
            >
              <b-form-input
                id="lastName"
                v-model="lastName"
                name="lastName"
                placeholder="Doe"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- email -->
          <b-form-group label="Email" label-for="email">
            <validation-provider
              #default="{ errors }"
              name="Email"
              vid="email"
              rules="email"
            >
              <b-form-input
                id="email"
                v-model="email"
                name="email"
                placeholder="john@example.com"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- phone number -->

          <b-form-group label="Phone Number" label-for="phone">
            <validation-provider
              #default="{ errors }"
              name="Phone"
              vid="phone"
              rules="required|integer"
            >
              <b-form-input
                id="phone"
                type="tel"
                v-model="phone"
                placeholder="1234 567 8900"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              <small class="text-danger" v-if="invalidPhone">Invalid Phone Number</small>
            </validation-provider>
          </b-form-group>

          <!-- last name -->
          <b-form-group label-for="company" label="Company">
            <validation-provider vid="company">
              <b-form-input
                id="company"
                v-model="company"
                name="Company"
                placeholder="PepeaSMS"
              />
            </validation-provider>
          </b-form-group>

          <!-- Form Actions -->
          <div class="d-flex align-items-center justify-content-between">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              :disabled="invalid || invalidPhone || formIsLoading || pristine"
              :class="{ disabled: invalid }"
            >
              <b-spinner small v-if="formIsLoading" />
              &nbsp; Submit
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email, integer } from "@validations";

import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BButton,
  BSpinner,
  BFormFile,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
    BSpinner,
    vSelect,
    BFormFile,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: ["id", "groups", "countries"],
  emits: ["contact-submited", "reset-id"],
  data() {
    return {
      group: [],
      oldGroup: [],
      country: null,
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      company: "",
      formIsLoading: false,
      required,
      email,
      integer,
    };
  },
  computed:{
    invalidPhone(){
      if(this.phone == "" || this.phone == null){
        return false;
      }
      let phoneSanitize = this.phone
      phoneSanitize = phoneSanitize.replaceAll(" ", '')
      if(phoneSanitize.substr(0, 1) == "+"){
        phoneSanitize = phoneSanitize.substr(1)
      }
      if(phoneSanitize.substr(0, 2) == "00"){
        phoneSanitize = phoneSanitize.substr(2)
      }
      if(phoneSanitize.substr(0, 1) == "0"){
        phoneSanitize = phoneSanitize.substr(1)
      }
      if(phoneSanitize.substr(0, 3) == "254"){
        phoneSanitize = phoneSanitize.substr(3)
      }

      if (phoneSanitize.length == 9) {
        return false;
      } else {
        return true;
      }
    }
  },
  methods: {
    editContacts() {
      this.formIsLoading = true;
      this.$refs.contactsEditFormValidator.validate().then((success) => {
        if (success) {
          this.$http
            .put("/contacts/update", {
              ID: this.id,
              group_id: this.group,
              old_group_id: this.oldGroup,
              country_id: this.country,
              first_name: this.firstName,
              last_name: this.lastName,
              email: this.email,
              phone: this.phone,
              company: this.company,
            })
            .then((response) => {
              this.formIsLoading = false;
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: response.data.message,
                  icon: "CheckIcon",
                  variant: "success",
                },
              });
              this.$emit("contact-submited");
              this.$root.$emit("bv::toggle::collapse", "sidebar-edit-contact");
              this.$nextTick(() => {
                this.$refs.contactsEditFormValidator.reset();
              });
            })
            .catch((error) => {
              for (let err of error.response.data.error) {
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: err,
                    icon: "AlertCircleIcon",
                    variant: "danger",
                  },
                });
              }
              this.formIsLoading = false;
            });
        }
      });
    },
  },
  mounted() {
    this.$root.$on("bv::collapse::state", (collapseId, isJustShown) => {
      if (collapseId == "sidebar-edit-contact" && isJustShown) {
        if (this.id && this.id != null && this.id != undefined) {
          this.formIsLoading = true;
          this.$http
            .get("/contacts/show/" + this.id)
            .then((response) => {
              this.country = response.data.data.country_id;
              this.firstName = response.data.data.first_name;
              this.lastName = response.data.data.last_name;
              this.email = response.data.data.email;
              this.phone = response.data.data.phone;
              this.company = response.data.data.company;
              this.group = [];
              for (const sGroup of response.data.data.ContactGroup) {
                this.group.push(sGroup.ID);
                this.oldGroup.push(sGroup.ID);
              }
              this.formIsLoading = false;
            })
            .catch((error) => {
              for (let err of error.response.data.error) {
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: err,
                    icon: "AlertCircleIcon",
                    variant: "danger",
                  },
                });
              }
              this.formIsLoading = false;
            });
        }
      }
      if (collapseId == "sidebar-edit-contact" && !isJustShown) {
        this.$emit("reset-id");
      }
    });
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
