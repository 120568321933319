<template>
  <b-sidebar
    id="sidebar-compose-message-contact"
    sidebar-class="sidebar-lg"
    bg-variant="white"
    shadow
    backdrop
    no-header
    right
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="
          d-flex
          justify-content-between
          align-items-center
          content-sidebar-header
          px-2
          py-1
        "
      >
        <h5 class="mb-0">Compose and Schedule SMS Messages</h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>
      <validation-observer ref="messageValidator" #default="{ invalid }">
        <b-form @submit.prevent ref="messageForm" class="p-2">
          <!-- Sender ID Select Field -->
          <b-form-group
            class="senderId-form-group"
            label="SMS Sender ID"
            label-for="sender"
          >
            <validation-provider
              #default="{ errors }"
              name="Sender ID"
              vid="sender"
              rules="required"
            >
              <v-select
                v-model="sender"
                :options="senderIds"
                label="text"
                :reduce="(singleSender) => singleSender.value"
                placeholder="Select Sender ID"
              >
                <span slot="no-options">Active Sender ID not found, kindly apply for Sender ID to start sending SMS(s)</span>
              </v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- Select All Recipients or Not -->
          <b-form-group
            label="How do you want to choose your recipients"
            v-slot="{ ariaDescribedby }"
            class="mt-2"
          >
            <validation-provider
              name="Choose Your Recipients"
              vid="selectAllContacts"
              rules="required"
            >
              <b-form-radio
                v-model="selectAllContacts"
                :aria-describedby="ariaDescribedby"
                name="selectAllContacts"
                :value="true"
                >Select all of my contacts ({{
                  allContactsCount
                }})</b-form-radio
              >
              <b-form-radio
                v-model="selectAllContacts"
                :aria-describedby="ariaDescribedby"
                name="selectAllContacts"
                :value="false"
                class="mt-1"
                >I want to choose my recipients</b-form-radio
              >
            </validation-provider>
          </b-form-group>

          <!-- How Recipients Should Be Added Radio Boxes -->
          <transition name="fade" mode="out-in">
            <b-form-group
              label="Please select your preferred contact entry option"
              v-slot="{ ariaDescribedby }"
              class="mt-2"
              v-if="selectAllContacts === false"
            >
              <validation-provider
                #default="{ errors }"
                name="Recipent Adding Method"
                vid="recipentAddingMethod"
                rules="required"
              >
                <b-form-radio
                  v-model="recipentAddingMethod"
                  :aria-describedby="ariaDescribedby"
                  name="recipentAddingMethod"
                  value="phoneNumber"
                  >Write each contact phone number</b-form-radio
                >
                <b-form-radio
                  v-model="recipentAddingMethod"
                  :aria-describedby="ariaDescribedby"
                  name="recipentAddingMethod"
                  value="contacts"
                  class="mt-1"
                  >Choose from your contacts</b-form-radio
                >
                <b-form-radio
                  v-model="recipentAddingMethod"
                  :aria-describedby="ariaDescribedby"
                  name="recipentAddingMethod"
                  value="groups"
                  class="mt-1"
                  >Choose contacts from your contact groups</b-form-radio
                >
                <b-form-radio
                  v-model="recipentAddingMethod"
                  :aria-describedby="ariaDescribedby"
                  name="recipentAddingMethod"
                  value="file"
                  class="mt-1"
                  >Add contacts from file</b-form-radio
                >

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </transition>

          <!-- Recipent Adding Different Fields  -->
          <transition name="fade" mode="out-in">
            <b-form-group
              label-for="phoneNumbers"
              label="Write each contact phone number"
              class="mt-2"
              v-if="
                recipentAddingMethod == 'phoneNumber' &&
                selectAllContacts === false
              "
              key="phoneNumbers"
            >
              <validation-provider
                name="Write each contact phone number"
                vid="phoneNumbers"
              >
                <b-form-tags
                  input-id="phoneNumbers"
                  name="phoneNumbers"
                  v-model.trim="phoneNumbers"
                  separator=" ,;"
                  placeholder="Add contact phone numbers ..."
                  input-type="number"
                ></b-form-tags>
                <small class="text-muted"
                  >Press <strong>enter</strong> to add each contact phone
                  number</small
                >
              </validation-provider>
            </b-form-group>

            <b-form-group
              label-for="contacts"
              label="Choose from your contacts"
              class="mt-2"
              v-if="
                recipentAddingMethod == 'contacts' &&
                selectAllContacts === false
              "
              key="contacts"
            >
              <validation-provider
                name="Choose from your contacts"
                vid="contacts"
              >
                <v-select
                  v-model="contacts"
                  :options="allContacts"
                  @search="filterContacts"
                  :filterable="false"
                  multiple
                  placeholder="Search for your contacts through contacts phone number, name, email or there company"
                >
                  <span slot="no-options"
                    >No contacts found from your search</span
                  >
                </v-select>
              </validation-provider>
            </b-form-group>

            <b-form-group
              label-for="groups"
              label="Choose contacts from your contact groups"
              class="mt-2"
              v-if="
                recipentAddingMethod == 'groups' && selectAllContacts === false
              "
              key="groups"
            >
              <validation-provider
                name="Choose contacts from your contact groups"
                vid="contacts"
              >
                <v-select
                  v-model="groups"
                  :options="allgroups"
                  label="text"
                  :reduce="(group) => group.value"
                  multiple
                  placeholder="Select Contact Groups"
                >
                  <span slot="no-options">No Contact Groups Found</span>
                </v-select>
              </validation-provider>
            </b-form-group>

            <b-form-group
              class="mt-2"
              v-if="
                recipentAddingMethod == 'file' && selectAllContacts === false
              "
              key="file"
            >
              <label
                for="file"
                class="d-flex align-items-baseline justify-content-between"
              >
                <span>Add contacts from file</span>
                <a
                  :href="
                    appUrl + '/uploads/samples/pepea_sms_sample_send_sms.xlsx'
                  "
                  download
                  >Upload Template</a
                >
              </label>
              <validation-provider name="Add contacts from file" vid="file">
                <b-input-group>
                  <b-input-group-prepend>
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="primary"
                      @click="file = null"
                    >
                      Reset
                    </b-button>
                  </b-input-group-prepend>
                  <b-form-file
                    v-model="file"
                    id="file"
                    input-id="file"
                    placeholder="Choose a .xlsx file or drop it here..."
                    drop-placeholder="Drop file here..."
                    accept=".xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  />
                </b-input-group>
                <small class="text-muted"
                  >Only accepts file with .xlsx extension</small
                >
              </validation-provider>
            </b-form-group>
          </transition>

          <!-- How SMS Sending should be scheduled Radio Boxes  -->
          <b-form-group
            label="Select the time you want this message to be sent"
            v-slot="{ ariaDescribedby }"
            class="mt-2"
          >
            <validation-provider
              name="Schedule the message"
              vid="schedule"
              rules="required"
            >
              <div class="d-flex justify-content-start align-items-center">
                <b-form-radio
                  v-model="schedule"
                  :aria-describedby="ariaDescribedby"
                  name="schedule"
                  value="now"
                  >Now</b-form-radio
                >
                <b-form-radio
                  v-model="schedule"
                  :aria-describedby="ariaDescribedby"
                  name="schedule"
                  value="later"
                  class="ml-1"
                  >Later</b-form-radio
                >
                <b-form-radio
                  v-model="schedule"
                  :aria-describedby="ariaDescribedby"
                  name="schedule"
                  value="repeat"
                  class="ml-1"
                  >Repeat</b-form-radio
                >
              </div>
            </validation-provider>
          </b-form-group>

          <!-- SMS Sending Scheduled For Later And Repeat Dates & Time Fields  -->
          <transition name="fade" mode="out-in">
            <b-form-group
              label="Select Later Date & Time"
              class="mt-2"
              label-for="later"
              key="later"
              v-if="schedule == 'later'"
            >
              <flat-pickr
                v-model="later"
                id="later"
                inputId="later"
                class="form-control"
                placeholder="Select Later Date & Time"
                :config="{
                  enableTime: true,
                  altInput: true,
                  altFormat: 'D, M d, Y h:m K',
                  dateFormat: 'Z',
                  minDate: todayDate,
                  clickOpens: true,
                }"
              />
            </b-form-group>

            <div v-if="schedule == 'repeat'">
              <b-form-group
                label="Initial Messaging Date & Time"
                class="mt-2"
                key="initialMessage"
              >
                <flat-pickr
                  v-model="initialMessage"
                  id="initialMessage"
                  inputId="initialMessage"
                  placeholder="Initial Messaging Date & Time"
                  class="form-control"
                  :config="{
                    enableTime: true,
                    altInput: true,
                    altFormat: 'D, M d, Y h:m K',
                    dateFormat: 'Z',
                    minDate: todayDate,
                  }"
                />
              </b-form-group>

              <b-form-group
                label="Repeat Schedule"
                v-slot="{ ariaDescribedby }"
                class="mt-2"
              >
                <validation-provider
                  name="Repeat Schedule"
                  vid="schedule"
                  class="d-flex justify-content-start align-items-center"
                >
                  <b-form-radio
                    v-model="repeatSchedule"
                    :aria-describedby="ariaDescribedby"
                    name="repeatSchedule"
                    value="Daily"
                    >Daily</b-form-radio
                  >

                  <b-form-radio
                    v-model="repeatSchedule"
                    :aria-describedby="ariaDescribedby"
                    name="repeatSchedule"
                    value="Weekly"
                    class="ml-1"
                    >Weekly</b-form-radio
                  >

                  <b-form-radio
                    v-model="repeatSchedule"
                    :aria-describedby="ariaDescribedby"
                    name="repeatSchedule"
                    value="Monthly"
                    class="ml-1"
                    >Monthly</b-form-radio
                  >

                  <b-form-radio
                    v-model="repeatSchedule"
                    :aria-describedby="ariaDescribedby"
                    name="repeatSchedule"
                    value="Custom"
                    class="ml-1"
                    >Custom</b-form-radio
                  >
                </validation-provider>
              </b-form-group>

              <transition name="fade" mode="out-in">
                <b-form-group
                  :label="'Repeat ' + repeatSchedule + ' Until'"
                  class="mt-2"
                  label-for="repeatUntil"
                  key="repeatUntilNonCustom"
                  v-if="
                    repeatSchedule == 'Daily' ||
                    repeatSchedule == 'Weekly' ||
                    repeatSchedule == 'Monthly'
                  "
                >
                  <b-form-datepicker
                    v-model="repeatUntil"
                    :min="todayDate"
                    :placeholder="'Repeat ' + repeatSchedule + ' Until'"
                    class="rounded"
                    locale="en"
                  />
                </b-form-group>

                <b-form-group
                  label="Custom Repeat Dates & Time"
                  class="mt-2"
                  label-for="repeatUntilCustom"
                  key="repeatUntilCustom"
                  v-if="repeatSchedule == 'Custom'"
                >
                  <flat-pickr
                    v-model="repeatUntilCustom"
                    id="repeatUntilCustom"
                    inputId="repeatUntilCustom"
                    placeholder="Custom Repeat Dates & Time"
                    class="form-control"
                    :config="{
                      mode: 'multiple',
                      enableTime: true,
                      altInput: true,
                      altFormat: 'D, M d, Y h:m K',
                      dateFormat: 'Z',
                      minDate: todayDate,
                    }"
                  />
                  <small
                    >Remember you can make multiple selection of dates &
                    time</small
                  >
                </b-form-group>
              </transition>
            </div>
          </transition>

          <b-form-group label="Message" class="mt-2">
            <validation-provider
              #default="{ errors }"
              name="Message"
              vid="message"
              rules="required"
            >
              <b-form-textarea
                v-model="message"
                input-id="message"
                id="message"
                placeholder="Message"
                rows="6"
              />

              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label
                    >{{ messageCharacters }} characters.
                    {{ noOfMessages }} message(s)</label
                  >
                  <small class="text-danger">{{ errors[0] }}</small>
                </div>
                <transition name="fade" mode="out-in">
                  <div v-if="recipentAddingMethod != 'phoneNumber'">
                    <small class="d-block mt-1"
                      >You can add the following variables in your message by
                      clicking on them</small
                    >
                    <div class="text-right">
                      <b-button
                        size="sm"
                        variant="secondary"
                        @click="addToMessage('{phone}')"
                        class="mr-1"
                      >
                        Phone
                      </b-button>

                      <b-button
                        size="sm"
                        variant="secondary"
                        @click="addToMessage('{first_name}')"
                        class="mr-1"
                      >
                        First Name
                      </b-button>

                      <b-button
                        size="sm"
                        variant="secondary"
                        @click="addToMessage('{last_name}')"
                      >
                        Last Name
                      </b-button>
                    </div>
                  </div>
                </transition>
              </b-form-group>
            </validation-provider>
          </b-form-group>

          <b-form-group class="mb-2">
            <b-form-checkbox
              id="status"
              v-model="status"
              name="status"
              :value="true"
            >
              I certify that this message abides by anti-spamming rules, restrictions on political messaging as well as international and local law and regulations. I have accepted the terms of use that govern the use of Pepea SMS™
            </b-form-checkbox>
            <small class="text-danger" v-if="!status && status != 'start'"
              >Agree to above terms and conditions to proceed</small
            >
          </b-form-group>

          <!-- Form Actions -->
          <div class="d-flex align-items-center justify-content-between">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              v-b-modal.review-message
              :disabled="invalid || formInvalid || (!status || status == 'start')"
              :class="{ disabled: invalid }"
            >
              <b-spinner small v-if="formIsLoading" class="mr-1" />
              Submit
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>
          <message-review
            @send-message="sendSMS"
            :message="message"
            :message-characters="messageCharacters"
            :no-of-messages="noOfMessages"
          ></message-review>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";

import {
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
  BFormRadio,
  BFormCheckbox,
  BFormTags,
  BFormFile,
  BFormTextarea,
  BFormDatepicker,
  BButton,
  BSpinner,
  VBModal,
  BSidebar,
} from "bootstrap-vue";
import vSelect from "vue-select";
import FlatPickr from "vue-flatpickr-component";
import Ripple from "vue-ripple-directive";
import MessageReview from "./MessageReview.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { $themeConfig } from "@themeConfig";

export default {
  directives: {
    Ripple,
    "b-modal": VBModal,
  },
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BFormRadio,
    BFormCheckbox,
    BFormTags,
    BFormFile,
    BFormTextarea,
    BFormDatepicker,
    BButton,
    BSpinner,
    BSidebar,
    FlatPickr,
    vSelect,
    // validations
    ValidationProvider,
    ValidationObserver,
    MessageReview,
  },
  props: {
    contactIds: {
      type: [Array, Boolean],
      default: false,
    },
  },
  data() {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const { appUrl } = $themeConfig.app;

    return {
      allContactsCount: 0,
      sender: null,
      senderIds: [],
      selectAllContacts: null,
      recipentAddingMethod: null,
      phoneNumbers: [],
      contacts: [],
      allContacts: [],
      groups: [],
      allgroups: [],
      file: null,
      schedule: null,
      later: null,
      initialMessage: null,
      repeatSchedule: null,
      repeatUntil: null,
      repeatUntilCustom: null,
      todayDate: new Date(today),
      message: "",
      status: "start",
      formIsLoading: false,
      appUrl: appUrl,
      required,
      intialization: true,
    };
  },
  computed: {
    formInvalid() {
      return (
        this.formIsLoading ||
        (this.selectAllContacts == false &&
          this.phoneNumbers.length == 0 &&
          this.contacts.length == 0 &&
          this.groups.length == 0 &&
          this.file == null) ||
        (this.schedule == "later" && this.later == null) ||
        (this.schedule == "repeat" &&
          (this.initialMessage == null || this.repeatSchedule == null)) ||
        (this.schedule == "repeat" &&
          (this.repeatSchedule == "Daily" ||
            this.repeatSchedule == "Weekly" ||
            this.repeatSchedule == "Monthly") &&
          this.repeatUntil == null) ||
        (this.schedule == "repeat" &&
          this.repeatSchedule == "Custom" &&
          (this.repeatUntilCustom == "" || this.repeatUntilCustom == null))
      );
    },
    messageCharacters() {
      return this.message.length;
    },
    noOfMessages() {
      if (this.message.length == 0) {
        return 1;
      } else {
        return Math.ceil(this.message.length / 160);
      }
    },
  },
  watch: {
    recipentAddingMethod() {
      if (!this.intialization) {
        this.phoneNumbers = [];
        this.contacts = [];
        this.groups = [];
        this.file = null;
      }
      this.intialization = false;
    },
    selectAllContacts(newVal) {
      if (newVal) {
        this.recipentAddingMethod = null;
      }
    },
    schedule() {
      this.later = null;
      this.initialMessage = null;
      this.repeatSchedule = null;
    },
    repeatSchedule(newVal, oldVal) {
      if (
        newVal === null ||
        ((newVal == "Daily" || newVal == "Weekly" || newVal == "Monthly") &&
          oldVal == "Custom") ||
        ((oldVal == "Daily" || oldVal == "Weekly" || oldVal == "Monthly") &&
          newVal == "Custom")
      ) {
        this.repeatUntil = null;
        this.repeatUntilCustom = null;
      }
    },
  },
  methods: {
    sendSMS() {
      this.formIsLoading = true;
      let formData = new FormData();
      if (this.sender) {
        formData.append("sender_id", this.sender);
      }
      if (this.selectAllContacts) {
        formData.append("select_all_contacts", this.selectAllContacts);
      }
      if (this.recipentAddingMethod) {
        formData.append("recipent_adding_method", this.recipentAddingMethod);
      }
      if (this.phoneNumbers.length && this.phoneNumbers.length > 0) {
        for (const phoneNumbers of this.phoneNumbers) {
          formData.append("phone_numbers", phoneNumbers);
        }
      }
      if (this.contacts.length && this.contacts.length > 0) {
        for (const contacts of this.contacts) {
          formData.append("contacts", contacts);
        }
      }
      if (this.groups.length && this.groups.length > 0) {
        for (const groups of this.groups) {
          formData.append("groups", groups);
        }
      }
      if (this.file) {
        formData.append("file", this.file);
      }
      if (this.schedule) {
        formData.append("schedule", this.schedule);
      }
      if (this.later) {
        formData.append("later", this.later);
      }
      if (this.initialMessage) {
        formData.append("initial_message", this.initialMessage);
      }
      if (this.repeatSchedule) {
        formData.append("repeat_schedule", this.repeatSchedule);
      }
      if (this.repeatUntil) {
        formData.append(
          "repeat_until",
          new Date(this.repeatUntil).toISOString()
        );
      }
      if (this.repeatUntilCustom != "" && this.repeatUntilCustom != null) {
        for (const repeatUntilCustom of this.repeatUntilCustom.split(", ")) {
          formData.append("repeat_until_custom", repeatUntilCustom);
        }
      }
      if (this.message) {
        formData.append("message", this.message);
      }
      this.$http
        .post("/sms/send", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "SMS Sending Request Successfully Recieved.",
              icon: "CheckIcon",
              variant: "success",
              text: response.data.message,
            },
          });
          this.$root.$emit(
            "bv::toggle::collapse",
            "sidebar-compose-message-contact"
          );
          setTimeout(()=> {
            this.resetEverything();
            this.$nextTick(() => {
              this.$refs.messageValidator.reset();
            });
          }, 300);
          this.formIsLoading = false;
        })
        .catch((error) => {
          for (let err of error.response.data.error) {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: err,
                icon: "AlertCircleIcon",
                variant: "danger",
              },
            });
          }
          this.formIsLoading = false;
        });
    },
    filterContacts(search, loading) {
      if (search == "") {
        this.allContacts = [];
        return;
      }
      loading(true);
      this.$http
        .get("/contacts/only-search?search=" + search)
        .then((response) => {
          this.allContacts = [];
          for (const contact of response.data.data) {
            this.allContacts.push(contact.code + contact.phone);
          }
          loading(false);
        });
    },
    addToMessage(field) {
      this.message += field;
    },
    addToContactPropContactPhone(phone) {
      this.intialization = true;
      this.selectAllContacts = false;
      this.recipentAddingMethod = "contacts";
      this.contacts = [];
      this.contacts.push(phone);
    },
    addToContactPropContactIds() {
      this.intialization = true;
      this.selectAllContacts = false;
      this.recipentAddingMethod = "contacts";
      if (this.contactIds && this.contactIds.length > 0) {
        this.formIsLoading = true;
        this.$http
          .post("/contacts/show-many", {
            ids: this.contactIds,
          })
          .then((response) => {
            this.contacts = [];
            for (const contact of response.data.data) {
              this.contacts.push(contact.code + contact.phone);
            }
            this.formIsLoading = false;
          })
          .catch((error) => {
            for (let err of error.response.data.error) {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: err,
                  icon: "AlertCircleIcon",
                  variant: "danger",
                },
              });
            }
            this.formIsLoading = false;
          });
      }
    },
    resetEverything() {
      this.sender = null;
      this.selectAllContacts = null;
      this.recipentAddingMethod = null;
      this.phoneNumbers = [];
      this.contacts = [];
      this.allContacts = [];
      this.groups = [];
      this.file = null;
      this.schedule = null;
      this.later = null;
      this.initialMessage = null;
      this.repeatSchedule = null;
      this.repeatUntil = null;
      this.repeatUntilCustom = null;
      this.message = "";
      this.status = "start";
    },
  },
  created() {
    this.$http.get("/contacts/count").then((response) => {
      this.allContactsCount = response.data.data;
    });

    this.$http.get("/sender-id/active").then((response) => {
      for (const senderId of response.data.data) {
        let pushSenderId = {
          value: senderId.ID,
          text: senderId.title,
        };
        this.senderIds.push(pushSenderId);
      }
    });

    this.$http
      .get("/contact-group/search")
      .then((response) => {
        for (const group of response.data.data) {
          let pushGroup = {
            value: group.ID,
            text: group.name,
          };
          this.allgroups.push(pushGroup);
        }
      })
      .catch((error) => {
        for (let err of error.response.data.error) {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: err,
              icon: "AlertCircleIcon",
              variant: "danger",
            },
          });
        }
      });
  },
  mounted() {
    this.$root.$on("bv::toggle::collapse", (sidebar, singlePhoneNum) => {
      if (sidebar == "sidebar-compose-message-contact") {
        if (singlePhoneNum) {
          this.addToContactPropContactPhone(singlePhoneNum);
        } else {
          this.addToContactPropContactIds();
        }
      }
    });
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.senderId-form-group {
  max-width: 20rem;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.fade-enter-active,
.fade-leave-active {
  transition: all 0.25s ease-in-out;
}
</style>