<template>
  <b-sidebar
    id="sidebar-import-contacts"
    sidebar-class="sidebar-lg"
    bg-variant="white"
    shadow
    backdrop
    no-header
    right
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Import Contacts</h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- Body -->
      <validation-observer ref="contactsImportFormValidator" #default="{ invalid }">
        <b-form class="p-2" @submit.prevent="importContacts" ref="contactsImportForm">
          
          <b-form-group label="Contacts Group" label-for="group">
              <validation-provider
                #default="{ errors }"
                name="Group"
                vid="group"
                rules="required"
              >
                <v-select
                    v-model="group"
                    :options="groups"
                    label="text"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :reduce="(group) => group.value"
                    multiple
                  />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
          </b-form-group>

          <b-form-group label="Contacts Country" label-for="country">
              <validation-provider
                #default="{ errors }"
                name="Country"
                vid="country"
                rules="required"
              >
                <v-select
                    v-model="country"
                    :options="countries"
                    label="text"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :reduce="(country) => country.value"
                  />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
          </b-form-group>

          <b-form-group>
            <label for="contacts" class="d-flex align-items-baseline justify-content-between">
              <span>Contacts Import File</span>
              <a :href="appUrl + '/uploads/samples/pepea_sms_sample_bulk_contacts_upload.xlsx'" download>Upload Template</a>
            </label>
              <validation-provider
                #default="{ errors }"
                name="Country"
                vid="contacts"
                rules="required"
              >
                <b-form-file
                  v-model="contacts"
                  id="contacts"
                  input-id="contacts"
                  placeholder="Choose a .xlsx file or drop it here..."
                  drop-placeholder="Drop file here..."
                  accept=".xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                />
                <small class="text-muted d-block">Only accepts file with .xlsx extension</small>
                <small class="text-danger d-block">{{ errors[0] }}</small>
              </validation-provider>
          </b-form-group>

          <!-- Form Actions -->
          <div class="d-flex align-items-center justify-content-between">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              :disabled="invalid || formIsLoading"
              :class="{ disabled: invalid }"

            >
              <b-spinner small v-if="formIsLoading" />
              &nbsp;
              Submit
            </b-button>


            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>

    </template>
  </b-sidebar>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";

import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BButton,
  BSpinner,
  BFormFile,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import { $themeConfig } from '@themeConfig';


export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
    BSpinner,
    vSelect,
    BFormFile,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props:{
    groupId:{
      type: [String, Boolean],
      default: false
    },
    groups:{
      type: Array,
    },
    countries:{
      type: Array,
    }
  },
  emits:["contact-submited"],
  data(){
    const { appUrl } = $themeConfig.app;
    return{
      contacts:null,
      group:[],
      country:null,
      formIsLoading:false,
      appUrl: appUrl,
      required,
    };
  },
  methods: {
    importContacts() {
      this.formIsLoading = true;
      this.$refs.contactsImportFormValidator.validate().then(success => {
        if (success) {
          let formData = new FormData();
          for (const group of this.group) {
            formData.append('group_id', group);
          }
          formData.append('country_id', this.country);
          formData.append('contacts', this.contacts);
          this.$http.post('/contacts/create/import',formData,
            {
              headers: {
                  'Content-Type': 'multipart/form-data'
              }
            })
            .then(response => {
              this.formIsLoading = false;
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: response.data.message,
                  icon: "CheckIcon",
                  variant: "success",
                },
              });
              this.$emit('contact-submited')
              this.$root.$emit('bv::toggle::collapse', 'sidebar-import-contacts')
               this.$nextTick(() => {
                this.$refs.contactsImportFormValidator.reset();
              });
              this.group = [];
              this.country = null;
              this.$refs.contactsImportForm.reset();
            })
            .catch(error => {
               for (let err of error.response.data.error) {
                  this.$toast({
                    component: ToastificationContent,
                    position: "top-right",
                    props: {
                      title:err,
                      icon: "AlertCircleIcon",
                      variant: "danger",
                    },
                  });
                }
                this.formIsLoading = false;
            })
        }
      });
    },
  },
  created(){
    if(this.groupId && this.group.length == 0){
      this.group.push(this.groupId);
    }
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

</style>